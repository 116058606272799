.login-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0;
  height: 100vh;
}

.login-container {
  position: relative;
  background: #ffffff;
  background-color: #fff;
  padding: 90px 4rem 100px 4rem !important;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  min-height: 100vh;
  min-width: 400px;
}

.login-wrapper .image-container {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-container > .row {
  width: 400px;
}

.login-wrapper .image-container a {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.titles {
  margin: 40px 0;
}

.titles h2 {
  font-size: 22px;
  color: #7a82a5;
}

.titles h3 {
  font-size: 17px;
  color: #7a82a5;
}

.titles a {
  color: #7a82a5;
}

.titles a:hover {
  color: #3b3e66;
}

.label-versao {
  color: #3e5084;
}

.networks-login {
  position: absolute;
  bottom: 50px;
}

.copyright {
  position: absolute;
  bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .login-container {
    width: 100vw;
    min-width: 100%;
  }
  .image-container {
    display: none;
  }
}

@media screen and (max-height: 704px) {
  .login-container {
    padding-top: 1.5rem !important;
  }
}

@media screen and (max-height: 635px) {
  .titles {
    margin: 20px 0;
  }
}
